<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="gateways"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:[`item.latitude`]="{ item }">
        {{ item.latitude ? item.latitude.toFixed(3) : "N/A" }}
      </template>

      <template v-slot:[`item.longitude`]="{ item }">
        {{ item.longitude ? item.longitude.toFixed(3) : "N/A" }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt, "yyyy-MM-dd HH:mm") }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              x-small
              color="error"
              v-on="on"
              @click.stop="openConfirm(item.gatewayId)"
              style="margin-right: 1rem"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("common.delete") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.map`]="{ item }">
        <PickLocation
          v-bind:resource="item"
          v-on:update-pos="updateGatewayPosition"
        />
      </template>
    </v-data-table>

    <v-dialog v-model="confirmDelete" max-width="450">
      <v-card>
        <v-card-title class="headline">
          {{ $t("common.verifyDelete") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary lighten-1"
            text
            @click="confirmDelete = false"
            >{{ $t("common.close") }}</v-btn
          >

          <v-btn color="primary" text @click="removeGateway()">{{
            $t("common.yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PickLocation from "../common/PickLocation.vue";

export default {
  name: "GatewayList",

  data() {
    return {
      confirmDelete: false,
      currentId: null,

      headers: [
        { text: this.$t("gateway.fields.name"), value: "name" },
        { text: this.$t("gateway.fields.macAddress"), value: "macAddress" },
        { text: this.$t("gateway.fields.latitude"), value: "latitude" },
        { text: this.$t("gateway.fields.longitude"), value: "longitude" },
        { text: this.$t("gateway.fields.createdAt"), value: "createdAt" },
        { text: "", value: "map", sortable: false },
        { text: "", value: "action", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState("gateways", ["gateways"]),
  },

  methods: {
    ...mapActions("gateways", [
      "getGateways",
      "deleteGateway",
      "updatePosition",
    ]),

    async removeGateway() {
      if (this.currentId == null || this.currentId == undefined) {
        // Respond with a message that no valid id was selected
        return;
      }

      this.confirmDelete = false;
      await this.deleteGateway(this.currentId);
    },

    openConfirm(id) {
      this.currentId = id;
      this.confirmDelete = true;
    },

    async updateGatewayPosition(pos, gateway) {
      await this.updatePosition({
        gatewayId: gateway.gatewayId,
        position: pos,
      });
    },
  },

  async created() {
    await this.getGateways();
  },

  components: {
    PickLocation,
  },
};
</script>
